.navsub {
  background-image: url("../../assets/bg_top.jpg");
  background-position: top;
  background-size: inherit;
  background-repeat: no-repeat;
  position: relative;
  top: 0px;
  width: 100%;
  height: 80px;
}

.navsub .navsub_content {
  max-width: 1170px;
  padding: 0rem 3rem;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

.navsub .navsub_content .bannersubpage {
  /* max-width: 1170px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 80px;
  position: relative; */
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 80px;
  position: absolute;
  top: 0;
  width: 100%;
}

.navsub_title {
  width: 40%;
  height: 46px;
  font-family: "UTM Alexander";
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 46px;
  color: #22689b;
  float: left;
}

.navsub_title_link {
  color: #22689b;
  text-decoration: none;
}
.navsub_links {
  flex: auto;
  display: flex;
  justify-content: right;
  align-items: center;
  list-style: none;
}

.navsub_links li {
  margin: 0;
  cursor: pointer;
  padding: 9px 24px;
}

.navsub_links li.item-menu {
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
}

.navsub_links li.item-menu .item-menu-link {
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
}

.navsub_links li.item-menu:hover {
  background: #ff9c07;
  color: white !important;
  border-radius: 20px;
  flex: none;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  padding: 9px 24px;
  display: flex;
}

.navsub_links li.item-menu .item-menu-link:hover {
  background: #ff9c07;
  color: white !important;
}

.navsub_smallscreen {
  display: none;
}

.navsub_smallscreen_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--color-black);
  transition: 0.5s ease;

  flex-direction: column;
  z-index: 5;
}

.navsub_smallscreen_overlay .overlay_close {
  font-size: 27px;
  color: var(--color-golden);
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 45px;
  z-index: 99;
}

.navsub_smallscreen_links {
  list-style: none;
  background: white;
  display: list-item;
  position: absolute;
  width: 50%;
  height: 100vh;
  right: 0;
  margin-top: 0;
}

.navsub_smallscreen_links li {
  margin: 2rem;
  cursor: pointer;
  color: var(--color-golden);
  font-size: 2rem;
  text-align: center;
  font-family: var(--font-base);
}
.navsub_smallscreen_links li a {
  text-decoration: none;
}

.navsub_smallscreen_links li:hover {
  color: var(--color-white);
}
.navsub_div_showmore {
  position: absolute;
  top: 50%;
  left: 62%;
  text-align: justify;
  /* transform: translateY(-50%); */
}

.navsub_btn_showmore {
  justify-content: center;
  align-items: center;
  width: 224px;
  height: 60px;
  background: #ff9c07;
  border-radius: 50px;
  font-family: "UTM Alexander";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;

  /* Gray 6 */

  color: #f2f2f2;
  border: none;
  cursor: pointer;
}

@media screen and (min-width: 2000px) {
  .navsub_logo img {
    width: 210px;
  }
}

@media screen and (max-width: 1150px) {
  .navsub_links {
    display: none;
  }

  .navsub_smallscreen {
    display: flex;
    position: absolute;
    right: 10%;
    color: #22689b;
  }

  .nav .navsub_content .bannersubpage {
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 80px;
    position: absolute;
  }

  .navsub_btn_showmore {
    width: 140px;
    height: 40px;
    font-size: 16px;
  }
}
@media screen and (max-width: 850px) {
  .navsub_title {
    width: 100%;
    text-align: center;
  }

  .navsub .navsub_content {
    padding: 0;
  }
}

@media screen and (max-width: 650px) {
  .navsub .navsub_content {
    padding: 0;
  }

  .navsub_login {
    display: none;
  }

  .navsub_logo img {
    width: 110px;
  }
  .navsub_title {
    width: 100%;
    text-align: center;
  }

  /* .navsub_title {
    display: none;
  } */
}

@media screen and (max-width: 390px) {
  .navsub_title {
    width: 100%;
    text-align: center;
  }
  .navsub .navsub_content {
    padding: 0;
  }
  .navsub_btn_showmore {
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    background: #ff9c07;
    border-radius: 50px;
    font-family: "UTM Alexander";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 32px;
    color: #f2f2f2;
    border: none;
    cursor: pointer;
  }

  .navsub_smallscreen_links li {
    margin: 2rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-base);
  }
}

@media screen and (max-width: 480px) {
  .navsub .navsub_content {
    padding: 0;
  }
  .navsub_btn_showmore {
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    background: #ff9c07;
    border-radius: 50px;
    font-family: "UTM Alexander";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 32px;
    color: #f2f2f2;
    border: none;
    cursor: pointer;
  }
}
