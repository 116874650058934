.order_section {
  margin: 0 auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 530px;
  background: #1f2129;
}
.order_content {
  position: relative;
  width: 70%;
  left: 50%;
  transform: translateX(-50%);
}

.order_top_title {
  width: 100%;
  text-align: center;
  padding-top: 66px;
}

.order_top_title1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #ffffff;
  text-align: center;
}
.order_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  color: #acacac;
}

.order_form_content {
  position: absolute;
  width: 100%;
  background: #292b32;
  border: 1px solid #595959;
  border-radius: 10px;
}
.order_form {
  padding: 40px;
}

.grid {
  padding: 10px !important;
}

.grid_center {
  text-align: center;
}

input.input {
  box-sizing: border-box;
  border: 1px solid #1f2129;
  background: #1f2129;
  border-radius: 6px;
  padding: 16px;
  width: 100%;
}

.select {
  box-sizing: border-box;
  background: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 50px;
  padding: 5px 10px;
  width: 100%;
  height: 48px;
}

.order_btn_buy {
  padding: 10px 30px;
  width: 250px;
  height: 50px;

  background: #1f2129;
  border-radius: 25px;
  border: none;
  cursor: pointer;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;

  color: #f8f8f8;
}

@media (min-width: 992px) and (max-width: 1199px) {
}
@media (min-width: 768px) and (max-width: 992px) {
}
/*tablet*/
@media (min-width: 480px) and (max-width: 768px) {
  .order_section {
    min-height: 450px;
  }
  .order_content {
    width: 90%;
  }
  .order_top_title {
    padding-top: 40px;
  }
  .order_top_title1 {
    font-size: 20px;
    line-height: 20px;
  }

  .order_form {
    padding: 30px;
  }

  .grid-label {
    font-size: 10pt !important;
  }

  .order_btn_buy {
    font-size: 14px;
    width: 200px;
  }
}
/*mobile*/
@media only screen and (max-width: 480px) {
  .order_section {
    min-height: 450px;
  }
  .order_top_title1 {
    font-size: 20px;
    line-height: 20px;
  }

  .order_text {
    font-size: 12px;
    line-height: 12px;
  }
  .order_content {
    width: 95%;
  }
  .grid-label {
    display: none;
  }

  .order_btn_buy {
    font-size: 14px;
    width: 200px;
  }
}
