.subpage {
  position: relative;
  width: 100%;
  background: #ffffff;
  height: 100vh;
}

.subpage_contain {
  max-width: 1170px;
  margin: auto;
  padding: 20px;
  position: relative;
  overflow: scroll;
  margin-bottom: 50px;
}

.subpage_div_img {
  background-position: center;
  background-size: contain;
  position: absolute;
  width: 50%;
  left: 0;
}

.subpage_content {
  position: absolute;
  top: 50%;
  right: 0;
  width: 55%;
  text-align: justify;
  transform: translateY(-50%);
}
.subpage_about {
  font-family: "UTM Alexander";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;

  /* Title */

  color: #333333;
}
.subpage_name {
  font-family: "UTM Alexander";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 67px;

  /* Secondary */

  color: #22689b;
}

.subpage_btn_list {
  position: absolute;
  bottom: 0;
}

.subpage_btn_buy {
  justify-content: center;
  align-items: center;
  width: 237px;
  height: 55px;
  background: #2f80ed;
  border-radius: 50px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #f2f2f2;
}

.subpage_btn_read {
  justify-content: center;
  padding: 14px 40px;
  width: 200px;
  height: 55px;
  margin-left: 20px;
  border: 1px solid #2f80ed;
  border-radius: 50px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #2f80ed;
}

@media screen and (max-width: 1150px) {
  .subpage_contain {
    max-width: 1170px;
    margin: auto;
    padding: 20px;
    position: relative;
    overflow: scroll;
    margin-bottom: 50px;
  }

  .subpage_content {
    position: absolute;
    top: 50%;
    right: 0;
    width: 100%;
    text-align: justify;
    transform: translateY(-50%);
  }
  .subpage_name {
    font-family: "UTM Alexander";
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 63px;
    color: #22689b;
  }
}

@media screen and (max-width: 480px) {
  .subpage_contain {
    max-width: 1170px;
    margin: auto;
    padding: 20px;
    position: relative;
  }

  .subpage_content {
    position: absolute;
    top: 50%;
    right: 0;
    width: 100%;
    text-align: justify;
    transform: translateY(-50%);
  }
  .subpage_name {
    font-size: 16px;
    line-height: 24px;
  }
  .subpage_content p {
    font-size: 14px;
  }
  .subpage_div_img {
    background-position: center;
    background-size: contain;
    position: absolute;
    width: 50%;
    left: 50%;
  }
}
