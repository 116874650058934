.comingsoon_content_section {
  margin: 0 auto;
  position: relative;
  background-color: #1f2129;
  min-height: 1042px;
}

.comingsoon {
  position: absolute;
  width: 100%;
  margin-top: 80px;
}

.comingsoon_title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  /* identical to box height, or 125% */

  color: #ffffff;
  text-align: center;
}

/* .comingsoon_content {
  margin-top: 40px;
} */
.comingsoon_content_ul {
  overflow-x: auto;
}

.comingsoon_content_links {
  /* display: flex; */
  align-items: center;
  display: flex;
  flex: auto;
  justify-content: center;
  list-style: none;
  padding: 0px !important;
}

.comingsoon_content_links li.item {
  color: white;
  font-family: Open Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  text-decoration: none;
}

.comingsoon_content_links li {
  cursor: pointer;
  margin: 0;
  padding: 9px 24px;
}

.comingsoon_list_item {
  /* position: absolute; */
  width: 100%;
  margin-top: 30px;
}

.comingsoon_content {
  padding: 0 16px;
}
.comingsoon_item {
  /* background-color: white;
  border-radius: 10px; */
  padding: 10px;
  margin-left: 5px;
  text-align: justify;
  margin-right: 5px;
}

.active_item {
  background: #373944;
  border-radius: 26px;
  text-align: center;
}
.rec .rec-arrow {
  color: #fff;
  background-color: #2f80ed;
  box-shadow: 0 0 2px 0 #333;
  opacity: 0.1;
}

.rec:hover .rec-arrow:hover {
  color: #fff;
  background-color: #2f80ed;
  box-shadow: 0 0 2px 0 #333;
  opacity: 0.9;
}

@media (min-width: 1200px) {
  .comingsoon_content_section {
    min-height: 1040px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .comingsoon_content_section {
    min-height: 740px;
  }
  .comingsoon_content_links {
    width: 1200px;
    margin-left: 20px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .comingsoon_content_section {
    min-height: 600px;
  }
  .comingsoon_content {
    padding: 0 16px;
  }

  .comingsoon_content_ul {
    overflow-x: auto;
  }

  .comingsoon_content_links {
    width: 1200px;
    margin-left: 20px;
  }
}
/*tablet*/
@media (min-width: 480px) and (max-width: 768px) {
  .comingsoon_content_section {
    min-height: 510px;
  }
  .comingsoon {
    margin-top: 40px;
  }
  .comingsoon_title {
    font-size: 20px;
    line-height: 20px;
  }
  .comingsoon_content_links li.item {
    font-size: 16px;
  }

  .comingsoon_content_ul {
    overflow-x: auto;
  }

  .comingsoon_content_links {
    width: 750px;
    margin-left: 20px;
  }
}
/*mobile*/
@media only screen and (max-width: 480px) {
  .comingsoon_content_section {
    min-height: 430px;
  }
  .comingsoon {
    position: absolute;
    width: 100%;
    margin-top: 40px;
  }
  .comingsoon_content {
    padding: 0 0px;
  }
  .comingsoon_title {
    font-size: 20px;
    line-height: 20px;
  }

  .comingsoon_list_item {
    margin-top: 0px;
    position: relative;
    width: 100%;
  }

  .comingsoon_item {
    padding: 0px;
    margin-left: 5px;
    text-align: justify;
    /* margin-right: 5px; */
  }

  .comingsoon_content_links li.item {
    font-size: 16px;
  }
  .comingsoon_content_links li {
    padding: 9px 10px;
    text-align: center;
  }

  .comingsoon_content_ul {
    overflow-x: auto;
  }

  .comingsoon_content_links {
    width: 600px;
    margin-left: 0px;
  }
}
