.navbar_content_section {
  margin: 0 auto;
  position: relative;
  background-color: #1f2129;
  min-height: 163px;
}

.banner_top {
  background-image: url("../../assets/bg_top.jpg");
  background-position: top;
  background-size: inherit;
  background-repeat: no-repeat;
  position: relative;
  top: 0px;
  width: 100%;
  height: 80px;
}

.nav_content {
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  position: relative;
}

.banner {
  /* max-width: 1170px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 80px;
  position: relative; */
  display: flex;
  align-items: center;
  height: 80px;
  position: absolute;
  top: 0;
  width: 100%;
}

.nav_title {
  width: 40%;
  height: 46px;
  font-family: "UTM Alexander";
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 46px;
  color: #22689b;
  float: left;
}
.nav_title_link {
  text-decoration: none;
  color: #22689b;
}
.nav-links {
  flex: auto;
  display: flex;
  justify-content: right;
  align-items: center;
  list-style: none;
}

.nav-links li {
  margin: 0;
  cursor: pointer;
  padding: 9px 24px;
}

.nav-links li.item-menu {
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
}

.nav-links li.item-menu:hover {
  background: #ff9c07;
  color: white !important;
  border-radius: 20px;
  flex: none;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  padding: 9px 24px;
  display: flex;
}

.nav-smallscreen {
  display: none;
}

.nav-smallscreen_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--color-black);
  transition: 0.5s ease;

  flex-direction: column;
  z-index: 5;
}

.nav-smallscreen_overlay .overlay_close {
  font-size: 27px;
  color: var(--color-golden);
  cursor: pointer;
  position: absolute;
  top: 26px;
  right: 60px;
  z-index: 99;
}

.nav-smallscreen_links {
  list-style: none;
  background: white;
  display: list-item;
  position: absolute;
  width: 50%;
  height: 100vh;
  right: 0;
  margin-top: 0;
}

.nav-smallscreen_links li {
  margin: 2rem;
  cursor: pointer;
  color: var(--color-golden);
  font-size: 22px;
  text-align: left;
  font-family: var(--font-base);
}
.nav-smallscreen_links li a {
  text-decoration: none;
  color: #23699b;
}

.nav-smallscreen_links li:hover {
  color: var(--color-white);
}
.nav_div_showmore {
  position: absolute;
  top: 400px;
  left: 60%;
  text-align: justify;
  /* transform: translateY(-50%); */
}

.nav_btn_showmore {
  justify-content: center;
  align-items: center;
  width: 224px;
  height: 60px;
  background: #ff9c07;
  border-radius: 50px;
  font-family: "UTM Alexander";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;

  /* Gray 6 */

  color: #f2f2f2;
  border: none;
  cursor: pointer;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .nav_title {
    margin-left: 50px;
  }
  .nav_div_showmore {
    position: absolute;
    top: 250px;
    left: 60%;
    text-align: justify;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .nav_title .nav_title_link {
    display: none;
  }
  .nav-links {
    display: none;
  }

  .nav-smallscreen {
    display: flex;
    position: absolute;
    right: 10%;
    color: #22689b;
  }

  .nav .nav_content .banner {
    /* margin: 0 auto;
    display: flex;
    align-items: center;
    height: 80px;
    position: absolute; */
    width: 100%;
  }

  .nav_div_showmore {
    position: absolute;
    top: 220px;
    left: 60%;
    text-align: justify;
  }

  .nav_btn_showmore {
    width: 140px;
    height: 40px;
    font-size: 16px;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .nav_title .nav_title_link {
    display: none;
  }
  .nav-links {
    display: none;
  }

  .nav-smallscreen {
    display: flex;
    position: absolute;
    right: 10%;
    color: #22689b;
  }

  .nav_div_showmore {
    position: absolute;
    top: 160px;
    left: 60%;
    text-align: justify;
  }

  .nav_btn_showmore {
    width: 140px;
    height: 40px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 240px) and (max-width: 480px) {
  .nav_title .nav_title_link {
    display: none;
  }
  .nav-links {
    display: none;
  }

  .nav-smallscreen {
    display: flex;
    position: absolute;
    right: 10%;
    color: #22689b;
  }

  .nav_btn_showmore {
    width: 140px;
    height: 40px;
    font-size: 16px;
  }
  .nav .nav_content {
    max-width: 390px;
    padding: 0rem 1rem;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }

  .nav_div_showmore {
    position: absolute;
    top: 75px;
    left: 60%;
    text-align: justify;
  }
  .nav_btn_showmore {
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 25px;
    font-size: 10px;
    line-height: 22px;
  }

  .nav-smallscreen_links li {
    cursor: pointer;
    color: var(--color-golden);
    font-size: 25px;
    width: 100%;
    left: 0;
    display: flex;
  }
}
