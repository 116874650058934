.container {
  max-width: 1170px;
  margin: auto;
  padding: 0 0;
  position: relative;
}

.footer_section {
  margin: 0 auto;
  position: relative;
  max-height: 84px;
  background: #262626;
  color: #bdbdbd;
  padding: 28px;
  text-align: center;
}
