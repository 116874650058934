.partner_content_section {
  margin: 0 auto;
  position: relative;
  background-color: #232530;
  min-height: 420px;
}

.partner {
  margin-top: 80px;
  position: absolute;
  width: 100%;
}

.partner_title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  /* identical to box height, or 125% */

  color: #ffffff;
  text-align: center;
}

.partner_content {
  margin-top: 60px;
  display: flex;
}

.partner_left {
  width: 50%;
}

.partner_left_title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
}

.partner_left_text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
  color: #ffffff;
  padding-top: 20px;
}

.partner_right {
  width: 50%;
  text-align: right;
}

.partner_item {
  /* background-color: white;
  border-radius: 10px; */
  padding: 10px;
  margin-left: 5px;
  text-align: justify;
  margin-right: 5px;
}

.rec .rec-pagination {
  display: none;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .partner_top_brand1 {
    margin-left: 40px;
    font-size: 20px;
  }
  .partner_top_brand2 {
    top: 35px;
    width: 100%;
    font-family: "UTM Alexander";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
    color: #22689b;
  }

  .partner_content_1 {
    font-size: 16px;
  }

  .partner_content_2 {
    font-size: 14px;
  }
  .partner_content {
    width: 48%;
    text-align: justify;
    margin-left: 30px;
  }
  .partner_div_img {
    width: 60%;
    right: 10px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .partner {
    padding: 0px 10px 0px 10px;
    margin-bottom: 80px;
  }
  .partner_content {
    width: 100%;
  }
  .partner_top_circle {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 0px;
  }
  .partner_top_brand1 {
    margin-left: 5px;
    font-size: 18px;
    line-height: 28px;
  }
  .partner_top_brand2 {
    font-size: 24px;
    margin-left: 30px;
    line-height: 28px;
  }

  .partner_div_img {
    width: 28%;
    right: 20px;
  }
}
/*tablet*/
@media (min-width: 480px) and (max-width: 768px) {
  .partner_content_section {
    min-height: 270px;
  }

  .partner {
    margin-top: 40px;
    position: absolute;
    width: 100%;
  }
  .partner_title {
    font-size: 20px;
    line-height: 20px;
  }
}
/*mobile*/
@media only screen and (max-width: 480px) {
  .partner_content_section {
    min-height: 300px;
  }

  .partner {
    margin-top: 40px;
  }
  .partner_title {
    font-size: 20px;
    line-height: 20px;
  }
}
