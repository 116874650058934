.image-gallery-bullets .image-gallery-bullets-container {
  margin: 0;
  padding: 0;
  text-align: center;
  position: absolute;
  width: 100%;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .slidebot_top_brand1 {
    margin-left: 40px;
    font-size: 20px;
  }
  .slidebot_top_brand2 {
    top: 35px;
    width: 100%;
    font-family: "UTM Alexander";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
    color: #22689b;
  }

  .slidebot_content_1 {
    font-size: 16px;
  }

  .slidebot_content_2 {
    font-size: 14px;
  }
  .slidebot_content {
    width: 48%;
    text-align: justify;
    margin-left: 30px;
  }
  .slidebot_div_img {
    width: 60%;
    right: 10px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .slidebot {
    padding: 0px 10px 0px 10px;
    margin-bottom: 80px;
  }
  .slidebot_content {
    width: 100%;
  }
  .slidebot_top_circle {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 0px;
  }
  .slidebot_top_brand1 {
    margin-left: 5px;
    font-size: 18px;
    line-height: 28px;
  }
  .slidebot_top_brand2 {
    font-size: 24px;
    margin-left: 30px;
    line-height: 28px;
  }

  .slidebot_div_img {
    width: 28%;
    right: 20px;
  }
}
/*tablet*/
@media (min-width: 480px) and (max-width: 768px) {
  .slidebot_content_section {
    min-height: 300px;
  }

  .slidebot {
    margin-top: 40px;
    position: absolute;
    width: 100%;
  }
  .slidebot_title {
    font-size: 20px;
    line-height: 20px;
  }
}
/*mobile*/
@media only screen and (max-width: 480px) {
  .slidebot_content_section {
    min-height: 300px;
  }

  .slidebot {
    margin-top: 40px;
  }
  .slidebot_title {
    font-size: 20px;
    line-height: 20px;
  }
}
