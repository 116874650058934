.introduce_content_section {
  margin: 0 auto;
  position: relative;
  background-color: #1f2129;
  min-height: 600px;
}

.introduce {
  margin-top: 80px;
  position: absolute;
}

.introduce_title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #ffffff;
  text-align: center;
}

.introduce_content {
  margin-top: 80px;
  display: flex;
}

.introduce_logo_first {
  display: none;
}

.introduce_left {
  width: 50%;
}

.introduce_left_title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
}

.introduce_left_text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
  color: #ffffff;
  padding-top: 20px;
}

.introduce_right {
  width: 50%;
  text-align: right;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .introduce_content {
    margin-top: 40px;
    padding: 0 16px;
  }
  /* .introduce_left {
    width: 45%;
  }
  .introduce_right {
    width: 45%;
    text-align: right;
  } */
}
@media (min-width: 768px) and (max-width: 992px) {
  .introduce_content {
    margin-top: 40px;
    padding: 0 16px;
  }
}
/*tablet*/
@media (min-width: 480px) and (max-width: 768px) {
  .introduce_content_section {
    min-height: 610px;
  }
  .introduce {
    margin-top: 40px;
    position: absolute;
  }
  .introduce_content {
    margin-top: 40px;
    display: block;
    padding: 0 16px;
  }

  .introduce_title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }
  .introduce_logo_first {
    display: block;
    text-align: center;
  }

  .introduce_left {
    width: 100%;
  }

  .introduce_right {
    display: none;
  }

  .introduce_left_title {
    font-size: 20px;
    line-height: 27px;
  }

  .introduce_left_text {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}
/*mobile*/
@media only screen and (max-width: 480px) {
  .introduce_content_section {
    min-height: 630px;
  }
  .introduce {
    margin-top: 40px;
    position: absolute;
  }
  .introduce_content {
    margin-top: 40px;
    display: block;
    padding: 0 16px;
  }

  .introduce_title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }
  .introduce_logo_first {
    display: block;
    text-align: center;
  }

  .introduce_left {
    width: 100%;
  }

  .introduce_right {
    display: none;
  }

  .introduce_left_title {
    font-size: 20px;
    line-height: 27px;
  }

  .introduce_left_text {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}
