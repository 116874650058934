.app_footer_section {
  margin: 0 auto;
  position: relative;
  height: auto;
  background: #313131;
  padding: 0px 10px 0px 10px;
}

.footer_containner {
  padding-bottom: 30px !important;
}
.footer_top {
  height: 147px;
  position: relative;
  border-bottom: 1px solid;
  border-color: white;
  border-bottom: 1px solid #737171;
}
.footer_bottom {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  list-style: none;
  height: 100%;
}

.footer_left {
  width: 35%;
}
.footer_center {
  width: 35%;
}
.footer_right {
  width: 30%;
}
.footer_item_info {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #c6c6c6;
  width: 95%;
}

.footer_top_title {
  font-family: "UTM Alexander";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;

  /* Main */

  color: #ff9c07;
  position: relative;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.footer_top_title1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  position: relative;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.footer_img_thongbao {
  height: 80px;
  float: right;
  background-position: center;
  background-size: auto;
}

.footer_bottom_title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;

  /* white */

  color: #ffffff;
}

.footer_subpage {
  color: #c6c6c6;
  text-decoration: none;
}
/* thiết bị di động */

/* @media screen and (max-width: 480px) {
  .footer_img_thongbao {
    height: 60px;
  }
  .footer_left {
    width: 100%;
  }
  .footer_center {
    width: 100%;
  }
  .footer_right {
    width: 100%;
  }
  .footer_bottom_title {
    font-size: 14px;
  }
  .footer_item_info {
    font-size: 10px;
    text-align: justify;
    line-height: 15px;
  }
}

@media screen and (max-width: 390px) {
  .footer_bottom_title {
    font-size: 14px;
  }
  .footer_item_info {
    font-size: 10px;
    text-align: justify;
  }
} */

@media (min-width: 992px) and (max-width: 1199px) {
}
@media (min-width: 768px) and (max-width: 992px) {
}
/*tablet*/
@media (min-width: 480px) and (max-width: 768px) {
}
/*mobile*/
@media only screen and (max-width: 480px) {
  .footer_section {
    font-size: 14px;
  }
}
